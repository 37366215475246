import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { getAllOrganisations } from "../../redux/slice/organisationsSlice";
import OrganisationSidebar from "../../components/sidebars/OrganisationSidebar";
import MenuItem from "../commoncomps/MenuItem";
import Navbar from "../commoncomps/Navbar";
import Loader from "../loaders/Loader";
import "../../pages/taskManagement/taskmanagement.css";
import Button1 from "../buttons/Button1";

const OrganisationWrapper = (props) => {
  const dispatch = useDispatch();
  const { slug } = useParams();
  const [loader, setLoader] = useState(false);
  const [limit, setLimit] = useState(10);
  const [loadMore, setLoadMore] = useState(false);
  const [updateIcon, setUpdateIcon] = useState(false);

  const user = localStorage.getItem("user");

  // eslint-disable-next-line no-unused-vars
  const { t } = useTranslation();
  const {
    getAllOrganisations: { allOrganisation },
  } = useSelector((state) => state.organisation);
  // console.log(allOrganisation);
  useEffect(() => {
    if (allOrganisation?.length === 0 || loadMore) {
      setLoader(true);
      dispatch(
        getAllOrganisations({
          limit: limit,
        })
      ).then(() => {
        setLoader(false);
        setLoadMore(false);
      });
      setUpdateIcon(Math.random(1, 10));
    }
  }, [dispatch, limit]);

  const loadMoreOrgs = () => {
    setLoadMore(true);
    setLimit((limit) => limit + 1);
  };

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="clearfix cd_dashboard_wrapper">
      <div className="cd_organisation_left_section">
        <OrganisationSidebar>
          {loader && <Loader />}
          {allOrganisation?.organizations?.map((organisation, index) => (
            <MenuItem
              key={index}
              className={`${slug === organisation.slug ? "cd-active-organisation" : ""}`}
            >
              <a className="cd-common-anchor-tag" href={`/${organisation.slug}/view-organisation`}>
                {organisation.organization_photo ? (
                  <img
                    className={`cd-organisation-icon ${slug === organisation.slug ? "cd-active-organisation-icon" : ""}`}
                    src={`https://codecms.codeholic.in/static/uploads/${organisation.organization_photo}?${updateIcon}`}
                    alt={organisation.title}
                  />
                ) : (
                  <img
                    className={`cd-organisation-icon ${slug === organisation.slug ? "cd-active-organisation-icon" : ""}`}
                    src="/organisation-logo.jpg"
                    alt={organisation.title}
                  />
                )}
              </a>
            </MenuItem>
          ))}
          {allOrganisation && allOrganisation?.total > limit && (
            <Button1 onClick={loadMoreOrgs}>Load more</Button1>
          )}
        </OrganisationSidebar>
      </div>
      <div className="cd_organisation_right_section">
        <Navbar />
        <div className="cd_dashboard_content">{props.children}</div>
      </div>
    </div>
  );
};

export default OrganisationWrapper;
