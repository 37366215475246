import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import {
  ORGANISATION_GET_ALL,
  ORGANISATION_USER_DETAILS,
  ORGANISATION_GET_PERMISSIONS,
  ORGANISATION_USERS,
} from "../../api";
import axiosFetch from "../../axiosConfig";

export const getAllOrganisations = createAsyncThunk("getAllOrganisations", async (data) => {
  let url = ORGANISATION_GET_ALL;
  // adding the limit and offset if they are passed with payload
  if (data.limit) {
    url += `?limit=${data.limit}`;
  }
  if (data.offset) {
    url += `&offset=${data.offset}`;
  }
  try {
    const resp = await axiosFetch.get(url);
    return resp;
  } catch (error) {
    return error.response;
  }
});

export const getOrgUserDetails = createAsyncThunk("getOrgUserDetails", async (uuid) => {
  // console.log(uuid);
  try {
    const resp = await axiosFetch.get(ORGANISATION_USER_DETAILS + `/${uuid}`);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

export const getAllPermissions = createAsyncThunk("getAllPermissions", async () => {
  try {
    const resp = await axiosFetch.get(ORGANISATION_GET_PERMISSIONS);
    // console.log(resp);
    return resp;
  } catch (error) {
    return error.response;
  }
});

export const getAllOrgUsers = createAsyncThunk("getAllOrgUsers", async () => {
  try {
    const resp = await axiosFetch.get(ORGANISATION_USERS);
    return resp.data;
  } catch (error) {
    return error.response;
  }
});

const organisationSlice = createSlice({
  name: "organisation",
  initialState: {
    getAllOrganisations: {
      allOrganisation: [],
    },
    getOrgUserDetails: {
      userDetails: [],
      error: {},
    },
    getAllPermissions: {
      allPermissions: [],
      error: {},
    },
    getAllOrgUsers: {
      allOrgUsers: [],
      error: {},
    },
  },
  extraReducers: (builder) => {
    // to store organisation in state
    builder.addCase(getAllOrganisations.fulfilled, (state, { payload }) => {
      state.getAllOrganisations.allOrganisation = payload?.data;
    });
    builder.addCase(getAllOrganisations.rejected, (state, { payload }) => {
      state.getAllOrganisations.allOrganisation = payload;
    });

    // to store user details in state
    builder.addCase(getOrgUserDetails.fulfilled, (state, { payload }) => {
      state.getOrgUserDetails.userDetails = payload;
    });
    builder.addCase(getOrgUserDetails.rejected, (state, { payload }) => {
      state.getOrgUserDetails.error = payload;
    });

    // to store permissions in state
    builder.addCase(getAllPermissions.fulfilled, (state, { payload }) => {
      state.getAllPermissions.allPermissions = payload.data;
    });
    builder.addCase(getAllPermissions.rejected, (state, { payload }) => {
      state.getAllPermissions.error = payload;
    });

    // to store all organisation user in state
    builder.addCase(getAllOrgUsers.fulfilled, (state, { payload }) => {
      state.getAllOrgUsers.allOrgUsers = payload;
    });
    builder.addCase(getAllOrgUsers.rejected, (state, { payload }) => {
      state.getAllOrgUsers.error = payload;
    });
  },
});

export default organisationSlice.reducer;
