import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import InputEmail from "../../components/inputs/InputEmail";
import InputField from "../../components/inputs/InputField";
import Label1 from "../../components/labels/Label1";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import StripeCreateOrder from "../paymentgateway/StripeCreateOrder";
import RazorpayCreateOrder from "../paymentgateway/RazorpayCreateOrder";
import axiosFetch from "../../axiosConfig";
import { GET_ALL_TAX_REGIONS } from "../../api";
import PaypalCreateOrder from "../paymentgateway/PaypalCreateOrder";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const Checkout = ({ setProgress }) => {
  const { t } = useTranslation();

  // getting subscription id from URL parameters
  const { suid } = useParams();
  // console.log(suid);

  const user = JSON.parse(localStorage.getItem("user"));
  // console.log(user)

  const [name, setName] = useState("");
  const [email, setEmail] = useState(user?.email ? user?.email : "");
  const [contact, setContact] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [city, setCity] = useState("");
  const [gateway, setGateway] = useState("");
  const [showGateway, setShowGateway] = useState(false);
  const [loader, setLoader] = useState(true);
  const [taxRegions, setTaxRegions] = useState([]);

  // paypal initial Options
  const initialOptions = {
    "client-id": process.env.REACT_APP_PAYPAL_API_KEY,
    currency: "USD",
    intent: "capture",
  };

  // getting all tax regions
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    const getTaxRegions = async () => {
      try {
        const resp = await axiosFetch.get(GET_ALL_TAX_REGIONS);
        // console.log(resp.data.data);
        setTaxRegions(resp.data.data);
        setLoader(false);
        setProgress(100);
      } catch (error) {
        setLoader(false);
        setProgress(100);
        // console.log(error.response);
      }
    };
    getTaxRegions();
  }, []);
  // constant to disable the next button if user not entered all values
  const isDisabled =
    name.length < 3 ||
    email.length < 1 ||
    contact.length < 10 ||
    address.length < 5 ||
    gateway === ""
      ? true
      : false;
  return (
    <div>
      <DashboardWrapper>
        <Heading2>{t("checkout")}</Heading2>
        {!showGateway && (
          <div className={`${loader ? "not-appeared" : "appeared"}`}>
            <div className="cd-input-container">
              <Label1>{t("name")}: </Label1>
              <InputField
                placeholder={t("placeholderName")}
                value={name}
                name="cd_checkout_username"
                onChange={(e) => setName(e.target.value)}
                autoComplete="on"
              ></InputField>
            </div>
            <div className="cd-input-container">
              <Label1>{t("email")}: </Label1>
              <InputEmail
                placeholder={t("placeholderEmail")}
                name="cd_checkout_user_email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              ></InputEmail>
            </div>
            <div className="cd-input-container">
              <Label1>{t("contact")}: </Label1>
              <InputField
                placeholder={t("placeholderContact")}
                type="tel"
                name="cd_checkout_user_contact"
                value={contact}
                onChange={(e) => setContact(e.target.value)}
                autoComplete="on"
              ></InputField>
            </div>
            <div className="cd-input-container">
              <Label1>{t("pincode")}: </Label1>
              <InputField
                placeholder={t("placeholderPinCode")}
                value={pincode}
                name="cd_checkout_user_pin"
                onChange={(e) => setPincode(e.target.value)}
              ></InputField>
            </div>
            <div className="cd-input-container">
              <Label1>{t("city")} : </Label1>
              <InputField
                placeholder={t("placeholderCity")}
                value={city}
                name="cd_checkout_user_city"
                onChange={(e) => setCity(e.target.value)}
              ></InputField>
            </div>
            <div className="cd-input-container">
              <Label1>{t("country")}: </Label1>
              <select
                name="taxCountry"
                id="taxCountry"
                className="cd-input-field"
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value="">Select</option>
                {taxRegions.map((data, index) => (
                  <option key={index} value={data.country}>
                    {data.country}
                  </option>
                ))}
              </select>
            </div>
            <div className="cd-input-container">
              <Label1>{t("state")}: </Label1>
              <select
                name="taxState"
                id="taxState"
                className="cd-input-field"
                onChange={(e) => setState(e.target.value)}
              >
                <option value="">Select</option>
                {taxRegions.map((data, index) => {
                  if (data.country === country) {
                    return data.state.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ));
                  }
                  return null;
                })}
              </select>
            </div>
            <div className="cd-input-container">
              <Label1>{t("address")}: </Label1>
              <InputField
                placeholder={t("placeholderAddress")}
                value={address}
                name="cd_checkout_user_add"
                onChange={(e) => setAddress(e.target.value)}
              ></InputField>
            </div>
            <div className="cd-input-container">
              <Label1>{t("paymentGateway")}: </Label1>
              <select
                className="cd-input-field"
                value={gateway}
                name="cd_checkout_user_addr"
                onChange={(e) => setGateway(e.target.value)}
              >
                <option value="">Select</option>
                <option value="stripe">Stripe</option>
                <option value="razorpay">Razorpay</option>
                <option value="paypal">Paypal</option>
              </select>
            </div>
            <Button1 onClick={() => setShowGateway(!showGateway)} disabled={isDisabled}>
              Next
            </Button1>
          </div>
        )}

        {showGateway && (
          <div>
            <Button1 onClick={() => setShowGateway(!showGateway)}>Back</Button1>
            {gateway === "stripe" && (
              <StripeCreateOrder
                name={name}
                email={email}
                suid={suid}
                state={state}
                city={city}
                country={country}
                address={address}
                pincode={pincode}
                phone={contact}
              />
            )}
            {gateway === "razorpay" && (
              <RazorpayCreateOrder
                name={name}
                email={email}
                suid={suid}
                state={state}
                city={city}
                country={country}
                address={address}
                pincode={pincode}
                phone={contact}
              />
            )}
            {gateway === "paypal" && (
              <PayPalScriptProvider options={initialOptions}>
                <PaypalCreateOrder
                  name={name}
                  email={email}
                  suid={suid}
                  state={state}
                  city={city}
                  country={country}
                  address={address}
                  pincode={pincode}
                  phone={contact}
                />
              </PayPalScriptProvider>
            )}
          </div>
        )}
      </DashboardWrapper>
    </div>
  );
};

export default Checkout;
