import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import { getAllTags } from "../../redux/slice/taskManagementSlice";
import { TmUPDATE_TAG, TmDELETE_TAG } from "../../api";
import Heading2 from "../../components/headings/Heading2";
import Loader2 from "../../components/loaders/Loader2";
import Modal from "../../components/modal/Modal";
import InputField from "../../components/inputs/InputField";
import InputColor from "../../components/inputs/InputColor";
import Label1 from "../../components/labels/Label1";
import Button1 from "../../components/buttons/Button1";
import axiosFetch from "../../axiosConfig";
import TmCreateTag from "./TmCreateTag";

const TmAllTags = ({ setProgress }) => {
  const dispatch = useDispatch();
  const { puid } = useParams();
  const { t } = useTranslation(); // constant to use this hook
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [tagToBeUpdate, setTagToBeUpdate] = useState(false);
  const [tagToBeDelete, setTagToBeDelete] = useState(false);
  const [openCreateTagModal, setOpenCreateTagModal] = useState(false);
  const [tagCreated, setTagCreated] = useState(false);
  const [tagTitle, setTagTitle] = useState("");
  const [tagColor, setTagColor] = useState("");
  const [updateTagResponse, setUpdateTagResponse] = useState({});
  const [deleteTagResponse, setDeleteTagResponse] = useState({});

  // calling the API of getAllTags
  useEffect(() => {
    setLoader(true);
    setProgress(40);
    dispatch(
      getAllTags({
        projectId: puid,
      })
    ).then(() => {
      setLoader(false);
      setProgress(100);
    });
  }, [puid, updateTagResponse, deleteTagResponse, tagCreated]);
  // getting the tags list from task management slice
  const {
    getTags: { allTags },
  } = useSelector((state) => state.taskManagement);
  // console.log(tagToBeUpdate);

  // setting the values of title
  useEffect(() => {
    setTagTitle(tagToBeUpdate?.title ? tagToBeUpdate?.title : "");
    setTagColor(tagToBeUpdate?.color ? tagToBeUpdate?.color : "");
  }, [tagToBeUpdate]);

  // call the API of update tag
  let updateTagPayload = {
    tag_id: tagToBeUpdate?.tuid,
    title: tagTitle,
    color: tagColor,
  };
  const updateTag = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(TmUPDATE_TAG, updateTagPayload);
      setUpdateTagResponse(resp.data);
      setLoader2(false);
    } catch (error) {
      setUpdateTagResponse(error.response);
      setLoader2(false);
    }
  };

  // call the API of delete tag
  const deleteTag = async (tag_id) => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.delete(TmDELETE_TAG + `/${tag_id}`);
      setDeleteTagResponse(resp);
      setLoader2(false);
    } catch (error) {
      setDeleteTagResponse(error.response);
      setLoader2(false);
    }
  };

  return (
    <OrganisationWrapper>
      {/* code block for tag creation */}
      <Button1 onClick={() => setOpenCreateTagModal(!openCreateTagModal)}>{t("createTag")}</Button1>
      {openCreateTagModal && (
        <Modal handleModal={() => setOpenCreateTagModal(!openCreateTagModal)}>
          <TmCreateTag projectId={puid} tagCreated={() => setTagCreated(!tagCreated)} />
        </Modal>
      )}

      <Heading2>{t("tags")} </Heading2>
      <div className={`cd-tag-list-container ${loader ? "not-appeared" : "appeared"}`}>
        {allTags?.result?.map((tag, index) => (
          <div key={index} className="cd-tag-list-element">
            {t("name")} :{" "}
            <span style={{ backgroundColor: tag.color }} className="cd-tag-title">
              {tag.title}
            </span>
            <span onClick={() => setTagToBeUpdate(tag)} className="cd-edit-tag-icon">
              <img height="15px" src="/edit.png" alt="" />
            </span>
            <span onClick={() => setTagToBeDelete(tag)} className="cd-edit-tag-icon">
              <img height="15px" src="/delete.png" alt="" />
            </span>
          </div>
        ))}
      </div>
      {/* to open the update tag modal conditonally */}
      {tagToBeUpdate && (
        <Modal handleModal={() => setTagToBeUpdate(false)}>
          <Heading2 align="center">{t("updateTag")} </Heading2>
          <div className="cd-input-container">
            <Label1>{t("name")} :</Label1>
            <InputField
              placeholder={t("placeholderTagTitle")}
              value={tagTitle}
              name="cd_tag_title"
              onChange={(e) => setTagTitle(e.target.value)}
            ></InputField>
          </div>
          <div className="cd-input-container">
            <Label1>{t("color")} :</Label1>
            <InputColor
              value={tagColor}
              name="cd_tag_color"
              onChange={(e) => setTagColor(e.target.value)}
            ></InputColor>
          </div>
          {/* show success or error response */}
          {updateTagResponse?.tuid && <div className="cd-success-msg">{t("tagUpdatedMsg")} !</div>}
          {updateTagResponse?.data?.detail && (
            <div className="cd-err-msg">{updateTagResponse?.data?.detail[0]?.msg}</div>
          )}
          {updateTagResponse?.data?.detail?.detail && (
            <div className="cd-err-msg">{updateTagResponse?.data?.detail?.detail[0]?.msg}</div>
          )}
          <Button1 onClick={updateTag}>
            {t("update")} {loader2 && <Loader2 />}
          </Button1>
        </Modal>
      )}

      {/* to open the delete tag modal conditonally */}
      {tagToBeDelete && (
        <Modal
          handleModal={() => {
            setTagToBeDelete(false);
            setDeleteTagResponse({});
          }}
        >
          <Heading2 align="center">{t("deleteTag")} </Heading2>
          <div>
            {t("deleteTagWarning")} "{tagToBeDelete?.title}"?
          </div>
          {/* show the success or error response */}
          {deleteTagResponse?.status === 204 && (
            <div className="cd-success-msg">{t("tagDeletedMsg")} !</div>
          )}
          {deleteTagResponse?.data?.detail && (
            <div className="cd-err-msg">{deleteTagResponse?.data?.detail[0]?.msg}</div>
          )}
          {deleteTagResponse?.data?.detail?.detail && (
            <div className="cd-err-msg">{deleteTagResponse?.data?.detail?.detail[0]?.msg}</div>
          )}
          <Button1 onClick={() => deleteTag(tagToBeDelete?.tuid)}>
            {t("yes")} {loader2 && <Loader2 />}
          </Button1>
          <Button1 onClick={() => setTagToBeDelete(false)}>{t("no")} </Button1>
        </Modal>
      )}

      {allTags?.total === 0 && <div className="cd-success-msg">{t("noTagFound")} !</div>}
    </OrganisationWrapper>
  );
};

export default TmAllTags;
