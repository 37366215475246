import { React, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { getAllOrganisations } from "../../redux/slice/organisationsSlice";
import DashboardWrapper from "../../components/wrappers/DashboardWrapper";
import Heading2 from "../../components/headings/Heading2";
import "./organisations.css";

const AllOrganisations = ({ setProgress }) => {
  const dispatch = useDispatch();
  // constant to use use translation
  const { t } = useTranslation();
  const [loader, setLoader] = useState(true);

  // getting all organisations
  useEffect(() => {
    setLoader(true);
    setProgress(40);
    dispatch(
      getAllOrganisations({
        limit: 10,
      })
    ).then(() => {
      setLoader(false);
      setProgress(100);
    });
  }, []);
  const {
    getAllOrganisations: { allOrganisation },
  } = useSelector((state) => state.organisation);
  // console.log(allOrganisation);

  return (
    <DashboardWrapper>
      <div>
        <Link to="/create-organisation" className="cd-common-anchor-tag">
          {t("organizationCreate")}
        </Link>
      </div>
      <Heading2>{t("organization")}</Heading2>
      <div className={`cd-organisations-list-container ${loader ? "not-appeared" : "appeared"}`}>
        {allOrganisation?.detail && (
          <div className="cd-err-msg">{allOrganisation?.detail[0]?.msg}</div>
        )}
        {allOrganisation?.detail?.detail && (
          <div className="cd-err-msg">{allOrganisation?.detail?.detail[0]?.msg}</div>
        )}
        {allOrganisation?.organizations?.map((org) => (
          <div key={org.orguid} className="cd-organisations-list-element">
            <div>
              {t("name")} : {org.title}
            </div>
            <div>
              {t("adminEmail")} : {org.admin.email}
            </div>
            <div>
              <a href={`/${org.slug}/view-organisation`} className="cd-common-anchor-tag">
                {t("select")}
              </a>
            </div>
          </div>
        ))}
      </div>
    </DashboardWrapper>
  );
};

export default AllOrganisations;
