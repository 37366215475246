import React from "react";

const InputNumber = (props) => {
  return (
    <input
      className={`cd-input-field ${props.className}`}
      id={props.id}
      type="number"
      name={props.name}
      placeholder={props.placeholder}
      onChange={props.onChange}
      autoComplete={props?.autoComplete ? "on" : "off"}
      min={props.min}
      value={props.value}
    ></input>
  );
};

export default InputNumber;
