import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, React, useEffect } from "react";
import Home from "./pages/Home";
import LoadingBar from "react-top-loading-bar";

import Login from "./pages/authentication/Login";
import SignUp from "./pages/authentication/SignUp";
import VerifyToken from "./pages/authentication/VerifyToken";
import ResetPassword from "./pages/authentication/ResetPassword";
import SendToken from "./pages/authentication/SendToken";

import Dashboard from "./pages/Dashboard";
import ViewProfile from "./pages/userprofile/ViewProfile";
import UploadProfilePhoto from "./pages/userprofile/UploadProfilePhoto";
import UpdateProfile from "./pages/userprofile/UpdateProfile";
import { ChangeCurrency } from "./components/commoncomps/ChangeCurrency";

import AllSubscriptions from "./pages/subscription/AllSubscriptions";
import SubscriptionDetails from "./pages/subscription/SubscriptionDetails";

import Checkout from "./pages/checkout/Checkout";
import PaymentSuccess from "./pages/paymentsuccess/PaymentSuccess";

import AllOrganisations from "./pages/organisation/AllOrganisations";
import OrganisationCreate from "./pages/organisation/OrganisationCreate";
import OrganisationUsers from "./pages/organisation/OrganisationUsers";
import OrganisationRoles from "./pages/organisation/OrganisationRoles";
import OrganisationUserDetails from "./pages/organisation/OrganisationUserDetails";
import OrganisationDetails from "./pages/organisation/OrganisationDetails";
import OrganisationCreateRole from "./pages/organisation/OrganisationCreateRole";
import VerifyUserInvitation from "./pages/organisation/VerifyUserInvitation";

import TmAllProject from "./pages/taskManagement/TmAllProjects";
import TmProjectDetails from "./pages/taskManagement/TmProjectDetails";
import TmAllTaskGroups from "./pages/taskManagement/TmAllTaskGroups";
import TmAllTags from "./pages/taskManagement/TmAllTags";
import TmProjectUsers from "./pages/taskManagement/TmProjectUsers";

import Notification from "./pages/notification/Notification";

import "./App.css";
import "./style/dashboard.css";
import "./style/navbar.css";
import "./style/subscription.css";
import "./pages/paymentgateway/paymentgateway.css";

function App() {
  const [progress, setProgress] = useState(0);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [showOnlineMsg, setShowOnlineMsg] = useState(false);

  // function to check the connection of user
  useEffect(() => {
    window.addEventListener("online", () => {
      setIsOnline(true); // change the is online state
      setShowOnlineMsg(true); // show the back to online msg
      setTimeout(() => {
        window.location.reload(); // reload the page
      }, 3000);
    });
    window.addEventListener("offline", () => {
      setIsOnline(false); // change the online state to false to show offline msg
    });
  }, []);

  return (
    <>
      <div className={`${progress === 100 ? "not-appeared-top-bar" : "appeared-top-bar"}`}>
        <LoadingBar
          color="#f55a06"
          shadow={true}
          height="3px"
          progress={progress}
          loaderSpeed={400}
          transitionTime={50}
          onLoaderFinished={() => setProgress(0)}
        />
      </div>

      {/* if user not online */}
      {!isOnline && (
        <div align="center" className="cd-connection-off-msg">
          You're offline, Please connect to the internet
        </div>
      )}
      {/* if user connected back to internet */}
      {showOnlineMsg && (
        <div align="center" className="cd-connection-on-msg">
          Back to online, please wait...
        </div>
      )}

      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />

          {/* authentication */}
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/sign-up" element={<SignUp />} />
          <Route exact path="/frontend-user/verify-token" element={<VerifyToken />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/send-email-verification-token" element={<SendToken />} />
          <Route exact path="/frontend-user/create-password" element={<ResetPassword />} />

          {/* profile */}
          <Route exact path="/view-profile" element={<ViewProfile setProgress={setProgress} />} />
          <Route exact path="/change-currency" element={<ChangeCurrency />} />
          <Route exact path="/update-profile" element={<UpdateProfile />} />
          <Route exact path="/update-profile-photo" element={<UploadProfilePhoto />} />

          {/* subscriptions */}
          <Route
            exact
            path="/all-subscriptions"
            element={<AllSubscriptions setProgress={setProgress} />}
          />
          <Route
            exact
            path="/subscription-details/:suid"
            element={<SubscriptionDetails setProgress={setProgress} />}
          />

          {/* organisations */}
          <Route
            exact
            path="/all-organisations"
            element={<AllOrganisations setProgress={setProgress} />}
          />
          <Route exact path="/create-organisation" element={<OrganisationCreate />} />
          <Route
            exact
            path="/:slug/view-organisation"
            element={<OrganisationDetails setProgress={setProgress} />}
          />
          <Route
            exact
            path="/:slug/users"
            element={<OrganisationUsers setProgress={setProgress} />}
          />
          <Route
            exact
            path="/:slug/roles"
            element={<OrganisationRoles setProgress={setProgress} />}
          />
          <Route exact path="/:slug/create-role" element={<OrganisationCreateRole />} />
          <Route
            exact
            path="/:slug/view-org-user/:uuid"
            element={<OrganisationUserDetails setProgress={setProgress} />}
          />
          <Route exact path="/frontend-user/invitation" element={<VerifyUserInvitation />} />

          {/* notification */}
          <Route
            exact
            path="/:slug/notifications"
            element={<Notification setProgress={setProgress} />}
          />

          {/* task management */}
          <Route
            exact
            path="/:slug/projects"
            element={<TmAllProject setProgress={setProgress} />}
          />
          <Route
            exact
            path="/:slug/project/:puid"
            element={<TmProjectDetails setProgress={setProgress} />}
          />
          <Route
            exact
            path="/:slug/project-users/:puid"
            element={<TmProjectUsers setProgress={setProgress} />}
          />
          <Route
            exact
            path="/:slug/task-groups/:puid"
            element={<TmAllTaskGroups setProgress={setProgress} />}
          />
          <Route exact path="/:slug/tags/:puid" element={<TmAllTags setProgress={setProgress} />} />

          {/* payments */}
          <Route exact path="/checkout/:suid" element={<Checkout setProgress={setProgress} />} />
          <Route exact path="/payment-success/:ouid/:status" element={<PaymentSuccess />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
