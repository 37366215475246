import React from "react";
import { useTranslation } from "react-i18next";

const Pagination = (props) => {
  // constant to use useTranslation hook
  const { t } = useTranslation();

  return (
    <div className="cd-pagination-container">
      <div className="cd-pagination-wrapper clearfix">
        <span className="cd-pagination-prev cd-pagination-element" onClick={props.onClickPrev}>
          {t("prev")}
        </span>
        <span className="cd-pagination-current cd-pagination-element"> {props.currentPage} </span>
        <span className="cd-pagination-next cd-pagination-element" onClick={props.onClickNext}>
          {t("next")}
        </span>
      </div>
    </div>
  );
};

export default Pagination;
