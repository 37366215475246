import { React, useEffect, useMemo, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getProjectTasks } from "../../redux/slice/taskManagementSlice";
import { ORGANIZATION_FILE_PREVIEW, TmREMOVE_TAG } from "../../api";

import Heading2 from "../../components/headings/Heading2";
// import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";
import Pagination from "../../components/pagination/Pagination";
import GoToPage from "../../components/pagination/GoToPage";
import TmUpdateTask from "./TmUpdateTask";
import TmAssignTask from "./TmAssignTask";
import TmAddSubTask from "./TmAddSubTask";
import TmUpdateCustomColumnValues from "./TmUpdateCustomColumnValues";
import TmDeleteCustomColumn from "./TmDeleteCustomColumn";
import TmAddTaskComment from "./TmAddTaskComment";
import TmAssignTag from "./TmAssignTag";
import axiosFetch from "../../axiosConfig";

const TmAllTasks = (props) => {
  // constant to use useDispatch hook
  const dispatch = useDispatch();
  // constant to use useNavigate hook
  const navigate = useNavigate();
  const [loader, setLoader] = useState(true);
  const [taskToBeUpdate, setTaskToBeUpdate] = useState({});
  const [taskUpdated, setTaskUpdated] = useState(false);
  const [taskToBeAssign, setTaskToBeAssign] = useState({});
  const [taskToAssignTag, setTaskToAssignTag] = useState({});
  const [subTaskToBeAdded, setSubTaskToBeAdded] = useState({});
  const [columnToBeDeleted, setColumnToBeDeleted] = useState(false);
  const [columnValueToBeUpdated, setColumnValueToBeUpdated] = useState(false);
  const [commentToBeAdded, setCommentToBeAdded] = useState({});
  const [showSubTask, setShowSubTask] = useState({});
  const [limit] = useState(10);
  // get the page number from URL search param
  const page = new URLSearchParams(window.location.search).get("page");
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [inputPage, setInputPage] = useState("");

  const projectId = props.projectId;
  // getting tasks from taskManagement state of redux
  const {
    getTasks: { allTasks },
  } = useSelector((state) => state.taskManagement);

  // getting project details from taskManagement state of redux
  const {
    getProjectDetails: { projectDetails },
  } = useSelector((state) => state.taskManagement);

  // getting the project tasks
  useEffect(() => {
    setLoader(true);
    dispatch(
      getProjectTasks({
        projectId: projectId,
        limit: limit,
        offset: limit * (currentPage - 1),
      })
    ).then(() => {
      setLoader(false);
    });
  }, [currentPage, props.newTaskCreated, props.customColumnCreated, taskUpdated]);

  // functions to handle the pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };
  // console.log(Math.round(allTasks?.total / limit));

  const handleNext = () => {
    setCurrentPage(parseInt(currentPage));
    if (currentPage >= Math.ceil(allTasks?.total / limit)) return;
    setCurrentPage(currentPage + 1);
    navigate(`?page=${currentPage + 1}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allTasks?.total / limit)) return;
    setCurrentPage(parseInt(inputPage));
    if (inputPage == 1) navigate(window.location.pathname);
    else navigate(`?page=${inputPage}`);
  };

  // function to call the API of remove tag
  const removeTag = async (payload) => {
    try {
      const resp = await axiosFetch.post(TmREMOVE_TAG, payload);
      if (resp.status === 201) {
        dispatch(
          getProjectTasks({
            projectId: projectId,
            limit: limit,
            offset: limit * (currentPage - 1),
          })
        );
      }
    } catch (error) {
      // console.log(error.response);
    }
  };

  // function to call the API of task media preview
  const getMediaPreview = async (task_id, media_id) => {
    try {
      const resp = await axiosFetch.get(
        ORGANIZATION_FILE_PREVIEW + media_id + `?task_id=${task_id}`
      );
      return resp.data.link;
    } catch (error) {
      return "Media Preview Fail";
    }
  };

  // memoize the task media preview URL
  const mediaURL = useMemo(() => {
    const mediaURL = {};
    if (allTasks?.tasks) {
      allTasks?.tasks?.map((task, index) => {
        if (task?.medias) {
          Object.entries(task.medias).map(async ([index1, media_id]) => {
            mediaURL[task?.tuid] = await getMediaPreview(task?.tuid, media_id);
          });
        }
      });
      return mediaURL;
    }
  }, [allTasks, currentPage]);

  return (
    <>
      <div className={`cd-task-list-container ${loader ? "not-appeared" : "appeared"}`}>
        <Heading2>Tasks :</Heading2>
        {allTasks?.total !== 0 ? (
          <>
            {/* new table design */}
            <div className="cd-task-table-container">
              <div className="cd-task-table-wrapper">
                <div className="cd-task-table-heads-container clearfix">
                  <div className="cd-task-table-head">Title</div>
                  <div className="cd-task-table-head">Comments</div>
                  <div className="cd-task-table-head">Medias</div>
                  <div className="cd-task-table-head">Tag</div>
                  <div className="cd-task-table-head">Assignee</div>
                  <div className="cd-task-table-head">Estimated Hours </div>
                  <div className="cd-task-table-head">Deadline</div>
                  {projectDetails?.columns?.map((column, index1) => (
                    <div className="cd-task-table-head" key={column.cuid}>
                      <span>{column?.title}</span>
                      <span
                        onClick={() =>
                          setColumnToBeDeleted({
                            columnId: column.cuid,
                            index: index1,
                          })
                        }
                        className="cd-edit-tag-icon"
                      >
                        <img height="10px" src="/delete.png" alt="delete" />
                      </span>
                    </div>
                  ))}
                </div>
                <div className="cd-task-table-cells-container">
                  {allTasks?.tasks?.map((task, index) => (
                    <Fragment key={task.tuid}>
                      <div className="cd-task-table-cell-row clearfix">
                        <div className="cd-task-list-cell cd-task-title-cell">
                          <div className="cd-task-cell-control">{task?.title}</div>
                          <div
                            onClick={() => setTaskToBeUpdate({ task: task, index: index })}
                            className="cd-edit-tag-icon cd-task-cell-control"
                          >
                            <img
                              height="10px"
                              src="/edit.png"
                              alt="edit"
                              align="right"
                              title="Edit task"
                            />
                          </div>
                          {task?.subtask?.length > 0 && (
                            <div
                              onClick={() => {
                                let tempVar = showSubTask?.taskid ? {} : { taskid: task.tuid };
                                setShowSubTask(tempVar);
                              }}
                              className="cd-edit-tag-icon cd-task-cell-control"
                            >
                              <img
                                height="10px"
                                src="/dropdown-icon.png"
                                alt="subtask"
                                align="right"
                                title="View subtask"
                                aria-expanded={
                                  showSubTask?.taskid === task?.tuid ? "true" : "false"
                                }
                              />
                            </div>
                          )}
                          {taskToBeUpdate.index === index && (
                            <Modal handleModal={() => setTaskToBeUpdate("")}>
                              <TmUpdateTask
                                task={taskToBeUpdate.task}
                                projectId={projectId}
                                limit={limit}
                                currentPage={currentPage}
                              />
                            </Modal>
                          )}
                          <div
                            className="cd-task-cell-control cd-add-subtask-button"
                            onClick={() => setSubTaskToBeAdded({ task: task, index: index })}
                          >
                            +
                          </div>
                        </div>
                        <div className="cd-task-list-cell">
                          <div className="cd-view-comments" align="center">
                            <div
                              className="cd-comment-icon cd-task-cell-control"
                              onClick={() => {
                                setCommentToBeAdded({ task: task, index: index });
                              }}
                            >
                              <img height="15px" src="/comment.png" />
                            </div>
                          </div>
                          {commentToBeAdded.index === index && (
                            <Modal handleModal={() => setCommentToBeAdded("")}>
                              <TmAddTaskComment taskId={commentToBeAdded.task.tuid} />
                            </Modal>
                          )}
                        </div>
                        <div className="cd-task-list-cell">
                          {task?.medias ? (
                            Object.entries(task.medias).map(([index, mediaId]) => {
                              const previewLink = mediaURL[task?.tuid];
                              return (
                                <>
                                  <a
                                    key={index}
                                    className="cd-common-anchor-tag"
                                    href={`https://drive.google.com/file/d/${mediaId}/view?usp=drivesdk`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    View File
                                  </a>
                                  <img
                                    className="cd-task-media-image-thumb"
                                    src={previewLink}
                                    alt=""
                                  />
                                </>
                              );
                            })
                          ) : (
                            <span>No Medias</span>
                          )}
                        </div>
                        <div className="cd-task-list-cell">
                          {task.tags_assigned.map((tag, index) => (
                            <div
                              className="cd-tag-title cd-task-cell-control"
                              key={tag.tuid + index}
                              style={{ backgroundColor: tag.tag.color, marginRight: "5px" }}
                            >
                              {tag.tag.title}{" "}
                              <div
                                onClick={() =>
                                  removeTag({ task_id: task.tuid, tag_id: tag.tag.tuid })
                                }
                                className="cd-cross-mark cd-task-cell-control"
                              >
                                &times;
                              </div>
                            </div>
                          ))}
                          <div
                            onClick={() => setTaskToAssignTag({ task: task, index: index })}
                            className="cd-edit-tag-icon cd-task-cell-control"
                            align="center"
                          >
                            <img height="10px" src="/edit.png" alt="edit" align="center" />
                          </div>
                          {taskToAssignTag.index === index && (
                            <Modal handleModal={() => setTaskToAssignTag("")}>
                              <TmAssignTag
                                task={taskToAssignTag.task}
                                projectId={projectId}
                                limit={limit}
                                currentPage={currentPage}
                              />
                            </Modal>
                          )}
                        </div>
                        <div className="cd-task-list-cell">
                          {task.assigned_to.map((assignee, index) => (
                            <div className="cd-task-cell-control" key={index}>
                              {assignee.user.username}
                            </div>
                          ))}
                          <div
                            onClick={() => setTaskToBeAssign({ task: task, index: index })}
                            className="cd-edit-tag-icon cd-task-cell-control"
                          >
                            <img height="10px" src="/edit.png" alt="edit" align="right" />
                          </div>
                          {taskToBeAssign.index === index && (
                            <Modal handleModal={() => setTaskToBeAssign("")}>
                              <TmAssignTask
                                task={task}
                                projectId={projectId}
                                limit={limit}
                                currentPage={currentPage}
                              />
                            </Modal>
                          )}
                        </div>
                        <div className="cd-task-list-cell">{task?.estimate_hours}</div>
                        <div className="cd-task-list-cell">{task?.deadline}</div>
                        {projectDetails?.columns?.map((column, index1) => {
                          // check for a value for current column
                          const columnIndex = task.column_values.findIndex(
                            (item) => item.column.cuid === column.cuid
                          );
                          const columnValue =
                            columnIndex !== -1 ? task.column_values[columnIndex].value.value : null;
                          return (
                            !column.is_deleted && (
                              <div
                                className="cd-task-list-cell cd-task-cell-control"
                                key={column?.cuid + index1}
                              >
                                <div className="cd-task-cell-control">
                                  {columnValue ? columnValue : ""}
                                </div>
                                <div
                                  align="right"
                                  onClick={() =>
                                    setColumnValueToBeUpdated({
                                      column: column, // all data of column
                                      index: index, // current index
                                      value: columnValue, // current column value
                                    })
                                  }
                                  className="cd-edit-tag-icon cd-task-cell-control"
                                >
                                  <img height="10px" src="/edit.png" alt="edit" align="right" />
                                </div>
                              </div>
                            )
                          );
                        })}
                        {/* delete custom column */}
                        {columnToBeDeleted.index === index && (
                          <Modal handleModal={() => setColumnToBeDeleted(false)}>
                            <TmDeleteCustomColumn
                              columnId={columnToBeDeleted?.columnId}
                              handleModal={() => setColumnToBeDeleted(false)}
                              projectId={projectId}
                            />
                          </Modal>
                        )}
                        {columnValueToBeUpdated.index === index && (
                          <Modal handleModal={() => setColumnValueToBeUpdated(false)}>
                            <TmUpdateCustomColumnValues
                              column={columnValueToBeUpdated?.column}
                              task={task}
                              projectId={projectId}
                              value={columnValueToBeUpdated.value}
                              taskUpdated={() => setTaskUpdated(!taskUpdated)}
                            />
                          </Modal>
                        )}
                      </div>
                      {subTaskToBeAdded.index === index && (
                        <Modal handleModal={() => setSubTaskToBeAdded("")}>
                          <TmAddSubTask
                            task={subTaskToBeAdded.task}
                            projectId={projectId}
                            limit={limit}
                            currentPage={currentPage}
                          />
                        </Modal>
                      )}

                      {task?.subtask.length > 0 &&
                        showSubTask?.taskid === task?.tuid &&
                        task?.subtask.map((subtask, index1) => (
                          <div
                            key={subtask?.tuid + index1}
                            className={`cd-subtask-row cd-task-table-cell-row clearfix ${showSubTask?.taskid ? "expanded" : "not-expanded"}`}
                          >
                            <div className="cd-task-list-cell">
                              <div className="cd-task-cell-control">{subtask?.title}</div>
                              <div
                                onClick={() =>
                                  setTaskToBeUpdate({ task: subtask, index1: subtask?.tuid })
                                }
                                className="cd-edit-tag-icon cd-task-cell-control"
                              >
                                <img height="10px" src="/edit.png" alt="edit" align="right" />
                              </div>
                              {taskToBeUpdate.index1 === subtask?.tuid && (
                                <Modal handleModal={() => setTaskToBeUpdate("")}>
                                  <TmUpdateTask
                                    task={subtask}
                                    projectId={projectId}
                                    limit={limit}
                                    currentPage={currentPage}
                                  />
                                </Modal>
                              )}
                            </div>
                            <div className="cd-task-list-cell">
                              <div className="cd-view-comments" align="center">
                                <div
                                  className="cd-comment-icon cd-task-cell-control"
                                  onClick={() =>
                                    setCommentToBeAdded({ task: subtask, index1: subtask.tuid })
                                  }
                                >
                                  <img height="15px" src="/comment.png" />
                                </div>
                              </div>
                              {commentToBeAdded?.index1 === subtask.tuid && (
                                <Modal handleModal={() => setCommentToBeAdded("")}>
                                  <TmAddTaskComment taskId={commentToBeAdded.task.tuid} />
                                </Modal>
                              )}
                            </div>
                            <div className="cd-task-list-cell"></div>
                            <div className="cd-task-list-cell">
                              {subtask.tags_assigned.map((tag, index) => (
                                <div
                                  className="cd-tag-title cd-task-cell-control"
                                  key={tag?.tuid + index}
                                  style={{ backgroundColor: tag.tag.color, marginRight: "5px" }}
                                >
                                  {tag.tag.title}{" "}
                                  <div
                                    onClick={() =>
                                      removeTag({ task_id: subtask.tuid, tag_id: tag.tag.tuid })
                                    }
                                    className="cd-cross-mark cd-task-cell-control"
                                  >
                                    &times;
                                  </div>
                                </div>
                              ))}
                              <div
                                onClick={() =>
                                  setTaskToAssignTag({ task: subtask, index1: index1 })
                                }
                                className="cd-edit-tag-icon cd-task-cell-control"
                                align="center"
                              >
                                <img height="10px" src="/edit.png" alt="edit" align="center" />
                              </div>
                              {taskToAssignTag?.index1 === index1 && (
                                <Modal handleModal={() => setTaskToAssignTag("")}>
                                  <TmAssignTag
                                    task={taskToAssignTag.task}
                                    projectId={projectId}
                                    limit={limit}
                                    currentPage={currentPage}
                                  />
                                </Modal>
                              )}
                            </div>
                            <div className="cd-task-list-cell">
                              {subtask.assigned_to.map((assignee, index) => (
                                <div className="cd-task-cell-control" key={index}>
                                  {assignee.user.username}
                                </div>
                              ))}
                              <div
                                onClick={() =>
                                  setTaskToBeAssign({ task: subtask, index1: subtask.tuid })
                                }
                                className="cd-edit-tag-icon cd-task-cell-control"
                              >
                                <img height="10px" src="/edit.png" alt="edit" align="right" />
                              </div>
                              {taskToBeAssign.index1 === subtask.tuid && (
                                <Modal handleModal={() => setTaskToBeAssign("")}>
                                  <TmAssignTask
                                    task={subtask}
                                    projectId={projectId}
                                    limit={limit}
                                    currentPage={currentPage}
                                  />
                                </Modal>
                              )}
                            </div>
                            <div className="cd-task-list-cell">{subtask?.estimate_hours}</div>
                            <div className="cd-task-list-cell">{subtask?.deadline}</div>
                            {/* <div className="cd-task-list-cell"></div> */}
                            {projectDetails?.columns?.map((column, index1) => {
                              // check for a value for current column
                              const columnIndex = subtask.column_values.findIndex(
                                (item) => item.column.cuid === column.cuid
                              );
                              const columnValue =
                                columnIndex !== -1
                                  ? subtask.column_values[columnIndex].value.value
                                  : null;
                              return (
                                !column.is_deleted && (
                                  <div
                                    className="cd-task-list-cell cd-task-cell-control"
                                    key={index1}
                                  >
                                    <div className="cd-task-cell-control">
                                      {columnValue ? columnValue : ""}
                                    </div>
                                    <div
                                      align="right"
                                      onClick={() =>
                                        setColumnValueToBeUpdated({
                                          column: column, // all data of column
                                          index: subtask?.tuid, // current index
                                          value: columnValue, // current column value
                                        })
                                      }
                                      className="cd-edit-tag-icon cd-task-cell-control"
                                    >
                                      <img height="10px" src="/edit.png" alt="edit" align="right" />
                                    </div>
                                  </div>
                                )
                              );
                            })}
                            {/* delete custom column */}
                            {columnToBeDeleted.index === subtask?.tuid && (
                              <Modal handleModal={() => setColumnToBeDeleted(false)}>
                                <TmDeleteCustomColumn
                                  columnId={columnToBeDeleted?.columnId}
                                  handleModal={() => setColumnToBeDeleted(false)}
                                  projectId={projectId}
                                />
                              </Modal>
                            )}
                            {columnValueToBeUpdated.index === subtask?.tuid && (
                              <Modal handleModal={() => setColumnValueToBeUpdated(false)}>
                                <TmUpdateCustomColumnValues
                                  column={columnValueToBeUpdated?.column}
                                  task={subtask}
                                  projectId={projectId}
                                  value={columnValueToBeUpdated.value}
                                  taskUpdated={() => setTaskUpdated(!taskUpdated)}
                                />
                              </Modal>
                            )}
                          </div>
                        ))}
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="cd-success-msg">No tasks!</div>
        )}
        {allTasks?.total !== 0 && (
          <>
            <Pagination
              onClickPrev={handlePrev}
              onClickNext={handleNext}
              currentPage={currentPage}
            ></Pagination>
            <GoToPage
              inputPage={inputPage}
              onClick={handleInputPage}
              onChange={(e) => setInputPage(e.target.value)}
            ></GoToPage>
          </>
        )}
      </div>
    </>
  );
};

export default TmAllTasks;
