import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getProjects } from "../../redux/slice/taskManagementSlice";
import GoToPage from "../../components/pagination/GoToPage";
import Pagination from "../../components/pagination/Pagination";
import OrganisationWrapper from "../../components/wrappers/OrganisationWrapper";
import TmCreateProject from "./TmCreateProject";
import Heading2 from "../../components/headings/Heading2";
import Button1 from "../../components/buttons/Button1";
import Modal from "../../components/modal/Modal";

const TmAllProject = ({ setProgress }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { slug } = useParams();
  const [limit] = useState(10);
  const [loader, setLoader] = useState(true);
  const [inputPage, setInputPage] = useState("");
  const page = new URLSearchParams(window.location.search).get("page");
  const [currentPage, setCurrentPage] = useState(page ? page : 1);
  const [showCreateProject, setShowCreateProject] = useState(false);
  const { t } = useTranslation(); // constant to use this hook

  // getting the all the projects
  useEffect(() => {
    setProgress(40);
    setLoader(true);
    dispatch(
      getProjects({
        limit: limit,
        offset: limit * (currentPage - 1),
      })
    ).then(() => {
      setProgress(100);
      setLoader(false);
    });
  }, [currentPage]);

  // functions to handle the pagination
  const handlePrev = () => {
    let tempCurrentPage = currentPage;
    if (currentPage > 1) {
      tempCurrentPage -= 1;
      setCurrentPage(parseInt(tempCurrentPage));
      navigate(`?page=${tempCurrentPage}`);
      if (tempCurrentPage === 1) {
        navigate(window.location.pathname);
        return;
      }
    }
  };
  // console.log(Math.round(allTasks?.total / limit));

  const handleNext = () => {
    setCurrentPage(parseInt(currentPage));
    if (currentPage >= Math.ceil(allProjects?.total / limit)) return;
    setCurrentPage(currentPage + 1);
    navigate(`?page=${currentPage + 1}`);
  };

  const handleInputPage = () => {
    if (inputPage < 0 || inputPage === "" || inputPage > Math.ceil(allProjects?.total / limit))
      return;
    setCurrentPage(parseInt(inputPage));
    if (inputPage == 1) navigate(window.location.pathname);
    else navigate(`?page=${inputPage}`);
  };

  const {
    getProjects: { allProjects },
  } = useSelector((state) => state.taskManagement);
  // console.log(allProjects);

  return (
    <OrganisationWrapper>
      <Button1 onClick={() => setShowCreateProject(!showCreateProject)}>
        {t("createProject")}
      </Button1>
      <Heading2>{t("projects")} </Heading2>
      <div className={`cd-projects-list-container ${loader ? "not-appeared" : "appeared"}`}>
        {allProjects?.total !== 0 ? (
          allProjects?.projects?.map((project, index) => (
            <div key={index} className="cd-project-list-element">
              <div>
                {t("name")} : {project.title}
              </div>
              <div>
                {t("description")} : {project.description}
              </div>
              <div>
                <Link to={`/${slug}/project/${project.puid}`} className="cd-common-anchor-tag">
                  {t("select")}
                </Link>
              </div>
            </div>
          ))
        ) : (
          <div className="cd-err-msg">No project found!</div>
        )}
        {allProjects?.data?.detail && (
          <div className="cd-err-msg">{allProjects?.data?.detail[0]?.msg}</div>
        )}
        {allProjects?.data?.detail?.detail && (
          <div className="cd-err-msg">{allProjects?.data?.detail?.detail[0]?.msg}</div>
        )}
      </div>
      {showCreateProject && (
        <Modal handleModal={() => setShowCreateProject(!showCreateProject)}>
          <TmCreateProject limit={limit} offset={limit * (currentPage - 1)} />
        </Modal>
      )}
      <Pagination
        onClickPrev={handlePrev}
        onClickNext={handleNext}
        currentPage={currentPage}
      ></Pagination>
      <GoToPage
        inputPage={inputPage}
        onClick={handleInputPage}
        onChange={(e) => setInputPage(e.target.value)}
      ></GoToPage>
    </OrganisationWrapper>
  );
};

export default TmAllProject;
