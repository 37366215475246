import React from "react";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { calculateAmount } from "../../redux/slice/subscriptionSlice";

import { CREATE_ORDER, CREATE_TRANSACTIONS, CALCULATE_AMOUNT } from "../../api";
import axiosFetch from "../../axiosConfig";
import Loader from "../../components/loaders/Loader";
import ApplyCoupon from "../../components/commoncomps/ApplyCoupon";

const PaypalCreateOrder = (props) => {
  const dispatch = useDispatch();
  //   const [{ options, isPending }, paypaldispatch] = usePayPalScriptReducer();
  const [loader, setLoader] = useState(true);
  const [loader2, setLoader2] = useState(false);
  const [createOrderResponse, setCreateOrderResponse] = useState("");
  const [applyCouponResponse, setApplyCouponResponse] = useState("");
  const [message, setMessage] = useState("");
  const [coupon, setCoupon] = useState("");

  // calculate the amount
  useEffect(() => {
    dispatch(
      calculateAmount({
        product_id: props.suid,
        coupon_code: "",
        state: props.state,
        country: props.country,
        currency: "USD",
      })
    );
  }, []);
  const {
    calculateAmountData: { amountDistribution },
  } = useSelector((state) => state.subscription);

  // function to create order
  useEffect(() => {
    setLoader(true);
    async function createOrder() {
      const resp = await axiosFetch.post(CREATE_ORDER, {
        name: props.name,
        address_1: props.address,
        address_2: props.address,
        city: props.city,
        suid: props.suid,
        postal_code: props.pincode,
        country_code: "IN",
        currency: "USD",
        state: props.state,
        country: props.country,
        phone: props.phone,
        gateway: "paypal",
        coupon_code: coupon,
      });
      if (resp?.data) {
        setCreateOrderResponse(resp.data);
        setLoader(false);
        // added temporary to remove warnings
        setMessage("");
        setCoupon("");
      }
    }
    createOrder();
  }, [applyCouponResponse]);

  // function to apply coupon
  const applyCoupon = async () => {
    setLoader2(true);
    try {
      const resp = await axiosFetch.post(CALCULATE_AMOUNT, {
        product_id: props.suid,
        coupon_code: coupon,
        state: props.state,
        country: props.country,
        currency: "USD",
      });
      setLoader2(false);
      setApplyCouponResponse(resp.data);
    } catch (error) {
      setLoader2(false);
    }
  };

  // function to create transactions
  const createTransactions = async (details, createOrderResponse) => {
    try {
      const resp = await axiosFetch.post(CREATE_TRANSACTIONS, {
        payment_id: details.id,
        payment_gateway: "paypal",
        order_id: createOrderResponse.ouid,
        status: details.status,
        payment_json: { details },
      });
      if (resp) {
        window.location.replace(`/payment-success/${details.id}}/success`);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  // function to create paypal order
  const onCreateOrder = async (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: (createOrderResponse?.final_amount || 0).toFixed(2),
            // value: "1.00",
          },
        },
      ],
    });
  };

  const onApproveOrder = (data, actions) => {
    return actions.order.capture().then((details) => {
      // console.log(details);
      createTransactions(details, createOrderResponse);
    });
  };
  return (
    <>
      {loader && <Loader />}
      <div className={`cd-paypal-checkout-container ${loader ? "not-appeared" : "appeared"}`}>
        <div className="cd-product-price">
          Price :
          <span className="cd-price-value">
            {createOrderResponse?.currency === "USD" ? "$" : ""}
            {createOrderResponse?.total_amount}
          </span>
        </div>
        <div className="cd-product-tax-container">
          Service Charges :-{" "}
          {amountDistribution?.taxes?.map((tax) => (
            <div key={tax.taxuid} className="cd-product-tax-list">
              <ul>
                {tax.fields.map((field) => (
                  <li key={field.title} className="cd-product-tax-list-element">
                    {field.title} :{" "}
                    <span className="cd-price-value">
                      {" "}
                      {createOrderResponse?.currency === "USD" ? "$" : ""}
                      {field.value}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="cd-product-coupon">Coupon Code : {createOrderResponse?.coupon_code}</div>
        <div className="cd-product-coupon-amount">
          Coupon Amount :{" "}
          <span className="cd-price-value">
            {createOrderResponse?.currency === "USD" ? "$" : ""}{" "}
            {createOrderResponse?.coupon_amount?.toFixed(2)}
          </span>
        </div>
        <div className="cd-product-final-price">
          Final Price :{" "}
          <span className="cd-price-value">
            {createOrderResponse?.currency === "USD" ? "$" : ""}
            {createOrderResponse?.final_amount}
          </span>
        </div>
        {message && <div className="cd-err-msg">{message}</div>}
        <ApplyCoupon
          couponCode={coupon}
          onChange={(e) => setCoupon(e.target.value)}
          applyCoupon={applyCoupon}
          loader={loader2}
        ></ApplyCoupon>

        {createOrderResponse?.final_amount && !loader && (
          <div className="cd-paypal-button-container">
            <PayPalButtons
              style={{ layout: "vertical" }}
              createOrder={(data, actions) => onCreateOrder(data, actions)}
              onApprove={(data, actions) => onApproveOrder(data, actions)}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default PaypalCreateOrder;
